/**
 * ! Change CustomButton by your component name
 */

import React from "react";
import PropTypes from "prop-types";
import { useToastContext } from "../../../context/toast-context.js";
import { initTranslations } from "../../../i18n/i18n.js";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";

const CustomButton = (_props) => {
	var props = _props.properties;
	const ticks = new Date().getTime() + Math.floor(Math.random() * 1000);
	const componentId = "custom-button-" + ticks;

	const navigate = useNavigate();

	const { t } = initTranslations();
	const { handleShowToast } = useToastContext();

	componentDidMount();

	function componentDidMount() {
		try {
			//console.log(`Loaded ${props.pageName} with id ${componentId}`);
		} catch (e) {
			onError(e);
		}
	}

	function onError(error) {
		var trNamespace = "common";
		if (props && props.trNamespace) {
			trNamespace = props.trNamespace;
		}
		handleShowToast("error", t("error", { ns: trNamespace }), `${error}`);
	}

	function clickCallback() {
		if (props.actionFunction != null && props.actionFunction.length > 0) {
			props.actionFunction.forEach((element) => {
				element();
			});
		} else {
			if (props.actionUrl != null) {
				navigate(props.actionUrl);
			}
		}
	}

	function playClick() {
		if (props.spritesheet.click) {
			var el = document.getElementById(`${componentId}-spritesheet`);
			el.classList.remove("is-active");
			if (el.classList.contains(props.spritesheet.name + "-hover"))
				el.classList.remove(props.spritesheet.name + "-hover");
			el.classList.add("is-active");
			setTimeout(() => {
				el.classList.remove("is-active");
			}, props.spritesheet.clickDuration);
		}
	}

	function playHover() {
		if (props.spritesheet.hover) {
			var el = document.getElementById(`${componentId}-spritesheet`);
			el.classList.remove(props.spritesheet.name);
			el.classList.add(props.spritesheet.name + "-hover");
			el.classList.add("is-active");
			setTimeout(() => {
				el.classList.remove("is-active");
				el.classList.remove(props.spritesheet.name + "-hover");
				el.classList.add(props.spritesheet.name);
			}, props.spritesheet.hoverDuration);
		}
	}

	function setButton() {
		if (props.spritesheet) {
			return (
				<>
					<Button
						key={componentId}
						id={props.id != null ? props.id : componentId}
						data-id={props.dataId != null ? props.dataId : componentId}
						className={"btn p-2 " + props.className}
						data_steps={props.dataSteps ?? 0}
						data_actual_step="0"
						onClick={() => {
							playClick();
							clickCallback();
						}}
						onMouseEnter={() => {
							playHover();
						}}
					>
						<div
							className={props.spritesheet.name}
							id={`${componentId}-spritesheet`}
						></div>

						{t(props.title, { ns: props.trNamespace })}
					</Button>
				</>
			);
		} else if (props.image) {
			return (
				<Button
					key={componentId}
					id={props.id != null ? props.id : componentId}
					data-id={props.dataId != null ? props.dataId : componentId}
					className={"btn p-2 " + props.className}
					data_steps={props.dataSteps ?? 0}
					data_actual_step="0"
					onClick={() => {
						clickCallback();
					}}
				>
					<img src={props.image} alt="logo" className="btn-img" />
					{props.title ? t(props.title, { ns: props.trNamespace }) : ""}
				</Button>
			);
		} else {
			return (
				<Button
					key={componentId}
					id={props.id != null ? props.id : componentId}
					data-id={props.dataId != null ? props.dataId : componentId}
					label={t(props.title, { ns: props.trNamespace })}
					icon={props.icon}
					className={"btn p-2 " + props.className}
					data_steps={props.dataSteps ?? 0}
					data_actual_step="0"
					onClick={() => {
						clickCallback();
					}}
				></Button>
			);
		}
	}

	return setButton();
};

CustomButton.propTypes = {
	title: PropTypes.string,
	trNamespace: PropTypes.string,
	icon: PropTypes.string,
	image: PropTypes.string,
	spritesheet: PropTypes.object,
	actionUrl: PropTypes.string,
	className: PropTypes.string,
	actionFunction: PropTypes.array,
	id: PropTypes.string,
	dataSteps: PropTypes.number,
	dataId: PropTypes.string
};

export default CustomButton;
