import sso from "./SSO/claim-access.js";
import productsService from "./application/product.js";
import companySso from "./datas/company.js";
import companyDatas from "./application/company.js";
import categoryDatas from "./datas/category-datas.js";
import order from "./application/order.js";

var isWorking = false;

export async function LoadData() {
	if (!isWorking) {
		isWorking = true;
		var name = null;
		// await sso.postClaimAccess("reynier.j@outlook.fr", "password");
		global.LOGGED_USER = {
			Email: "",
			Password: ""
		};
		await sso.postClaimAppAccess();
		var data = null;
		if (global.APP_TOKEN != null && global.APP_TOKEN != null) {
			var company = await LoadCompanyData(global.APP_TOKEN);
			var products = await LoadProductData(global.APP_TOKEN);
			var categories = CheckCategoriesWithProducts(
				await LoadCategoryData(global.APP_TOKEN),
				products
			);
			var orders = await LoadOrder(global.APP_TOKEN);
			name = global.LOGGED_USER.Email;
			var companyDetails = null;
			if (company.companyDetails != null && company.companyDetails.length > 0) {
				companyDetails = company.companyDetails[0];
			}
			var companyName = companyDetails.CompanyName;
			data = {
				name,
				companyName,
				companyDetails,
				company,
				categories,
				products,
				orders
			};
		}
		isWorking = false;
		return data;
	}
}

export async function LoadDetailsData() {
	if (!isWorking) {
		isWorking = true;
		var name = null;
		// await sso.postClaimAccess("reynier.j@outlook.fr", "password");
		global.LOGGED_USER = {
			Email: "",
			Password: ""
		};
		var data = null;
		var company = await LoadCompanyDataLocal();
		if (company == null) {
			await sso.postClaimAppAccess();

			if (global.APP_TOKEN != null && global.APP_TOKEN != null) {
				company = await LoadCompanyData(global.APP_TOKEN);
			}
		}
		name = global.LOGGED_USER.Email;
		var companyDetails = null;
		if (company.companyDetails != null && company.companyDetails.length > 0) {
			companyDetails = company.companyDetails[0];
			setGlobalVars(companyDetails);
		}
		var companyName = companyDetails.CompanyName;
		data = {
			name,
			companyName,
			companyDetails,
			company
		};
		data = JSON.parse(JSON.stringify(data));
		isWorking = false;
		return data;
	}
}

export async function LoadCategoryData(token) {
	var item = await categoryDatas.GetFilter(token);
	// filter to get only IsActive = true
	item = item.filter((x) => x.IsActive == true);
	// sort by PositionCategory then by CategoryName
	item.sort((a, b) => a.PositionCategory - b.PositionCategory);

	return item;
}
export async function LoadProductData(token) {
	var item = await productsService.GetFilter(token);
	// filter to get only IsActive = true
	item = item.filter((x) => x.IsActive == true);
	return item;
}
export async function LoadCompanyDetails(token) {
	var item = await companySso.GetOne(token);
	return item[0];
}

export async function LoadCompanyDataLocal() {
	var item = await companyDatas.GetOneLocal();
	if (
		item != null &&
		item.companyDetails != null &&
		item.companyDetails.length > 0
	)
		setGlobalVars(item.companyDetails[0]);
	return item;
}
export async function LoadCompanyData(token) {
	var item = await companyDatas.GetOne(token);
	return item[0];
}
export async function LoadOrder(token) {
	var item = await order.GetFilter(token);
	return item;
}

export function CheckCategoriesWithProducts(categories, products) {
	// Check if all categories have at least one product products
	var categoriesWithProducts = [];
	products = products.filter((product) => product.IsActive == true);
	var prodList = [];
	products.forEach((product) => {
		if (product.ProductOptions != null && product.ProductOptions.length > 0) {
			if (product.ProductOptions[0].IsVisible) {
				prodList.push(product);
			}
		}
	});
	categories.forEach((category) => {
		var categoryProducts = prodList.filter(
			(product) => product.CategoryId == category.Id
		);
		if (categoryProducts.length > 0) {
			categoriesWithProducts.push(category);
		}
	});
	return categoriesWithProducts;
}

export function LoadProductsFromCategory(categoryId, products) {
	var categoryProducts = products.filter(
		(product) => product.CategoryId == categoryId && product.IsActive == true
	);
	return categoryProducts;
}

function setGlobalVars(item) {
	global.cpname = item.CompanyName;
	global.cplogo = item.CompanyLogo;
}
