/**
 * ! Change OrderThumbnail by your component name
 */

import React from "react";
import PropTypes from "prop-types";
import { initTranslations } from "../../../i18n/i18n.js";
const _companyModel = require("../../models/company.js");

const OrderThumbnail = (_props) => {
	var props = _props.properties;
	const componentId =
		"OrderThumbnail-" + Math.random().toString(36).substring(7);

	const { t } = initTranslations();
	var _company = _companyModel.getCompanyDatas();
	componentDidMount();

	function componentDidMount() {
		try {
			//console.log(`Loaded ${props.pageName} with id ${componentId}`);
		} catch (e) {
			onError(e);
		}
	}
	function onError(error) {
		console.log(error);
		console.log(t("common:error"));
		//handleShowToast("error", t("error", { ns: props.trNamespace }), `${error}`);
	}
	function setQty(add, item) {
		var el = document.getElementById("total-qty-" + item.BasketId);
		if (el != null) {
			var qty = getQty(item);
			if (add) {
				qty++;
			} else {
				if (qty > 1) {
					qty--;
				}
			}
			el.innerHTML = qty;
			item.TotalQty = qty;
			setTotalPrice(item);
		}
	}
	function getQty(item) {
		var qty = 1;
		var el = document.getElementById("total-qty-" + item.BasketId);
		if (el != null) {
			qty = parseInt(el.innerHTML);
		}
		return qty;
	}
	function setTotalPrice(item) {
		// get element total-price
		var totalPrice = document.getElementById("total-price-" + item.BasketId);
		// set visible
		if (totalPrice != null) {
			var ttUnit = getTotalPrice(item);
			var qty = document.getElementById("total-qty-" + item.BasketId).innerHTML;
			totalPrice.innerHTML = (parseFloat(ttUnit) * parseInt(qty)).toFixed(2);
		}
	}
	function getTotalPrice(item) {
		var price = parseFloat(item.TotalUnitPrice);
		return price.toFixed(2);
	}
	function setImage() {
		if (_company.ShowProductImage == false) return;
		// var item = props.item.Item;
		// if (
		// 	item.ProductImage != null &&
		// 	item.ProductImage != "" &&
		// 	!item.ProductImage.toLowerCase().startsWith("http")
		// ) {
		// 	return (
		// 		<img
		// 			alt={item.Product}
		// 			src={`data:image/png;base64,${item.ProductImage}`}
		// 			className="img-sm border-rounded"
		// 		/>
		// 	);
		// }
		return null;
	}
	function compIngredients({
		ingredients,
		header,
		color,
		showPrice,
		elementId,
		compType,
		basketId
	}) {
		if (ingredients.length > 0) {
			return (
				<div className="row text-start">
					<div className="col-sm-12 font-bold">
						<span className={`text-${color}`}>{header}</span>
					</div>

					<div className="col-sm-12 m-1 font-bold">
						{ingredients.map((ingredient, count) => {
							count++;
							var price = "";
							if (showPrice) {
								var value = parseFloat(ingredient.value);
								if (value > 0) {
									price =
										" " +
										"(+" +
										value.toFixed(2) +
										" " +
										_company.currency +
										")";
								}
							}
							return (
								<span
									className={`badge m-1 bg-${color}`}
									key={count}
									id={`${elementId}-${ingredient.id}`}
									data-type={compType}
									data-bid={basketId}
									data-iid={ingredient.id}
								>
									{ingredient.name}
									{price}
								</span>
							);
						})}
					</div>
				</div>
			);
		}
	}
	function setRemoveIngredients() {
		var ingredients = props.item.RemovedIngredients;
		return compIngredients({
			ingredients: ingredients,
			header: t("without", { ns: props.trNamespace }),
			color: "danger",
			showPrice: false,
			elementId: `remove-order-ing-${props.item.BasketId}`,
			compType: "remove-order-ing",
			basketId: props.item.BasketId
		});
	}
	function setAddIngredients() {
		var ingredients = props.item.AddedIngredients;
		return compIngredients({
			ingredients: ingredients,
			header: t("with", { ns: props.trNamespace }),
			color: "success",
			showPrice: true,
			elementId: `add-order-ing-${props.item.BasketId}`,
			compType: "add-order-ing",
			basketId: props.item.BasketId
		});
	}
	function setAdditionalProducts() {
		var additionalProducts = props.item.AdditionalProducts;
		if (additionalProducts.length > 0) {
			return (
				<div className="row text-start">
					<div className="col-sm-12 font-bold">
						<span className={`text-primary`}>
							{t("additional-products", { ns: props.trNamespace })}
						</span>
					</div>

					<div className="col-sm-12 m-1 font-bold">
						{additionalProducts.map((product, count) => {
							count++;
							return (
								<span
									className={`badge m-1 bg-primary`}
									key={count}
									id={`additional-product-${product.BasketId}`}
								>
									{product.productName}
								</span>
							);
						})}
					</div>
				</div>
			);
		}
	}
	function setSelectedVariant() {
		var variant = props.item.Variant;
		if (variant != null) {
			var price = "";

			var value = parseFloat(variant.variantPrice);
			if (value > 0) {
				price = " " + "(" + value.toFixed(2) + " " + _company.currency + ")";
			}

			return (
				<div className="row text-start">
					<div className="col-sm-12 m-1 font-bold">
						<span className={`badge m-1 bg-info`}>
							{variant.variantName}
							{price}
						</span>
					</div>
				</div>
			);
		}
	}
	return (
		<div
			className={`card text-center m-1 animate blur delay-${props.count++}`}
			key={`${componentId}`}
			id={`order-item-${props.item.BasketId}`}
		>
			<div className="card-header bg-secondary">
				<div
					className={`container-inline animate glow delay-${props.count + 1}`}
				>
					{setImage()}
					<div className="start-0">
						<h5 className="card-title m-2  text-start">
							<span className="font-bold">{props.item.Product}</span>
						</h5>
						{setSelectedVariant()}
					</div>
				</div>
			</div>
			<div className="card-body bg-light">
				<div className={`row animate slide delay-${props.count + 2}`}>
					{setAdditionalProducts()}
				</div>
				<div className={`row animate slide delay-${props.count + 3}`}>
					{setRemoveIngredients()}
				</div>
				<div className={`row animate slide delay-${props.count + 4}`}>
					{setAddIngredients()}
				</div>
			</div>
			<div className="card-footer bg-light">
				<div className="container-inline-centered">
					<div className={`row animate glow delay-${props.count + 5}`}>
						<div className="col-sm-12  m-2 ">
							<span>{t("quantity", { ns: props.trNamespace })}</span>
						</div>
						<div className="col-sm-12 m-2 ">
							<div className="container-inline-centered font-bold ">
								<button
									className="btn btn-outline-primary  btn-sm btn-round btn-minus-qty"
									data-item-id={props.item.BasketId}
									onClick={() => setQty(false, props.item)}
								>
									<i className="fa fa-solid fa-minus"></i>
								</button>
								<div className="m-2">
									<span id={`total-qty-${props.item.BasketId}`}>
										{props.item.TotalQty}
									</span>
								</div>
								<button
									className="btn btn-outline-primary btn-sm btn-round btn-plus-qty"
									data-item-id={props.item.BasketId}
									onClick={() => setQty(true, props.item)}
								>
									<i className="fa fa-solid fa-plus"></i>
								</button>
							</div>
						</div>
					</div>
					<div className="row ">
						<div className="col-sm-12  m-2 ">
							<span>{t("price", { ns: props.trNamespace })}</span>
						</div>
						<div className="col-sm-12 m-2 ">
							<h6
								className=" font-bold"
								id={`total-price-${props.item.BasketId}`}
							>
								<span>{props.item.TotalPrice}</span>
								<span>&nbsp;{_company.currency}</span>
							</h6>
						</div>
					</div>
					<div className="row ">
						<div className="col-sm-12  m-2 "></div>
						<div className="col-sm-12 m-2 ">
							<button
								className="btn btn-transparent text-danger m-2 btn-order-delete"
								data-item-id={props.item.BasketId}
							>
								<i className="text-danger fas fa-trash"></i>{" "}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

OrderThumbnail.propTypes = {
	item: PropTypes.object,
	trNamespace: PropTypes.string,
	count: PropTypes.number
};

export default OrderThumbnail;
