import Schema from "../db-schema/main-db-schema";
// #region Camping Style Post

var db = null;
var table = Schema.products_Table;

/**
 * Retrieves all Camping Style Post from the local database.
 * @returns {Promise<Array<Object>>} A promise that resolves to an array of user pets.
 */
async function getAll() {
	if (db == null) {
		db = await Schema.openDb();
	}
	return await db.getAll(table);
}

/**
 * Retrieves a user pet by its ID from the local database.
 * @param {number} Id - The ID of the user pet to retrieve.
 * @returns {Object|null} The user pet object if found, or null if not found.
 */
async function getOne(Id) {
	if (db == null) {
		db = await Schema.openDb();
	}
	var cp = await getAll();
	if (cp.length > 0) {
		//check if the computerId already exists in any of the records
		var found = cp.find((x) => x.Id === Id);
		if (found != null) {
			return found;
		}
	}
	return null;
}

/**
 * Creates a new user pet record in the local database.
 * @param {Object} userPet - The user pet object to be created.
 * @returns {Promise<boolean>} - A promise that resolves to true if the user pet record was created successfully, or false if the user pet already exists.
 */
async function createOne(item) {
	var cp = await getAll();
	if (cp.length > 0) {
		//check if the computerId already exists in any of the records
		var found = cp.find((x) => x.Id === item.Id);
		if (found != null) {
			// remove the existing record
			await db.delete(table, item.Id);
		}
	}
	if (db == null) {
		await Schema.openDb();
	}
	await db.add(table, item);
	return true;
}

/**
 * Updates the user's pet in the local database.
 * @param {Object} userPet - The user's pet object to be updated.
 * @returns {Promise<boolean>} - A promise that resolves to true if the update is successful, false otherwise.
 */
async function updateOne(item) {
	if (db == null) {
		await Schema.openDb();
	}
	var cp = await getOne(item.Id);
	if (cp == null) {
		return false;
	}
	await db.put(table, item);
	return true;
}

/**
 * Deletes a user pet from the database.
 * @param {Object} userPet - The user pet object to be deleted.
 * @returns {Promise<boolean>} - A promise that resolves to true if the user pet was deleted successfully, or false otherwise.
 */
async function deleteOne(item) {
	if (db == null) {
		await Schema.openDb();
	}
	var cp = await getOne(item.Id);
	if (cp == null) {
		return false;
	}
	await db.delete(table, item.Id);
	return true;
}

// #endregion

export default {
	createOne,
	getAll,
	getOne,
	updateOne,
	deleteOne
};
