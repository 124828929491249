import { FilterMatchMode } from "primereact/api";
import { Component } from "react";
import * as actionsAnims from "../animations/actions-animations";
const _CompanyModel = require("../../components/models/company.js");
const company = _CompanyModel.getCompanyDatas();

class AreaProperties extends Component {
	// Constructor:
	constructor(props) {
		super(props);
		this.dataTableCols = this.dataTableCols.bind(this);
		this.dataTableFilters = this.dataTableFilters.bind(this);
		this.DataTableProperties = this.DataTableProperties.bind(this);
		this.CreationModalProperties = this.CreationModalProperties.bind(this);
		this.CreateFormFields = this.CreateFormFields.bind(this);
		this.CustomCanvasProperties = this.CustomCanvasProperties.bind(this);
	}

	/**
	 * Create datatable using defined properties.
	 * @param {*} props
	 * @param {*} localService
	 * @returns
	 */
	DataTableProperties(props, localService) {
		return {
			pageName: props.pageName,
			trNamespace: props.trNamespace,
			service: localService,
			columnsDefinition: this.dataTableCols(),
			filters: this.dataTableFilters(),
			globalFilterFields: ["Name"],
			editMode: "row",
			toastMessageFields: ["Name"],
			editButton: {
				icon: "fa-solid fa-circle-info",
				className: "button-datatable-info"
			},
			deleteButton: {
				icon: "fa-solid fa-trash-xmark",
				className: "button-datatable-danger"
			},
			cancelButton: {
				icon: "fa-solid fa-square-xmark",
				className: "button-datatable-warning"
			},
			saveButton: {
				icon: "fa-solid fa-floppy-disk-circle-arrow-right",
				className: "button-datatable-success"
			},
			deleteModal: {
				icon: "fa fa-4x fa-solid fa-block-question",
				header: "modal.delete.title",
				message: "modal.delete.message",
				buttonYes: "modal.delete.button-yes-label",
				buttonNo: "modal.delete.button-no-label"
			}
		};
	}

	CreationModalProperties(createFunction, formContent) {
		return {
			componentId: "create-area-modal",
			componentName: "modal.create.title",
			trNamespace: "menu",
			buttonName: "modal.create.button-label",
			buttonIcon: "pi pi-plus",
			showButton: {
				title: null,
				trNamespace: "menu",
				icon: "fa-solid fa-circle-info",
				actionUrl: "",
				className: "btn p-component btn-icon-only btn-transparent"
			},
			useTranslation: false,
			buttons: [
				{
					title: "cancel",
					trNamespace: "common",
					icon: "fa-solid fa-square-xmark",
					actionUrl: `${company.companyId}/menu`,
					className: "btn-danger",
					isCloseModal: true
				},
				{
					title: "add",
					trNamespace: "common",
					icon: "fa-solid fa-basket-shopping-plus",
					actionUrl: `${company.companyId}/menu`,
					className: "btn-primary",
					actionFunction: [
						() => {
							createFunction.forEach((element) => {
								element();
							});
						}
					],
					isCloseModal: false
				}
			],
			content: formContent
		};
	}

	AddToCartModalProperties(
		createFunction,
		formContent,
		item,
		dataSteps = 1,
		canOrder = false,
		showButtonAdditionalClassName = "btn-primary"
	) {
		var btnId = `add-to-cart-button-id-${item.Id}`;
		var btns = [];
		if (canOrder) {
			btns = [
				{
					title: "cancel",
					trNamespace: "common",
					icon: "fa-solid fa-square-xmark m-2",
					actionUrl: `${company.companyId}/menu`,
					className: "btn-danger",
					isCloseModal: true
				},
				{
					title: "add",
					trNamespace: "common",
					icon: "fa-solid fa-basket-shopping-plus m-2",
					actionUrl: `${company.companyId}/menu`,
					className: "btn-primary",
					id: btnId,
					dataSteps: dataSteps,
					actionFunction: [
						() => {
							createFunction.forEach((element) => {
								element();
							});
						}
					],
					isCloseModal: false
				}
			];
		} else {
			btns = [
				{
					title: "cancel",
					trNamespace: "common",
					icon: "fa-solid fa-square-xmark m-2",
					actionUrl: `${company.companyId}/menu`,
					className: "btn-danger",
					isCloseModal: true
				}
			];
		}
		var showButton = {
			title: "detail",
			trNamespace: "menu",
			icon: "fa-solid fa-circle-info m-1",
			actionUrl: "",
			className: `btn ${showButtonAdditionalClassName} btn-sm btn-md-height w-100 `
		};
		if (canOrder) {
			showButton = {
				title: "add",
				trNamespace: "common",
				icon: "fa-solid fa-basket-shopping-plus m-1",
				actionUrl: "",
				className: `btn ${showButtonAdditionalClassName} btn-sm btn-md-height w-100 `
			};
		}
		return {
			componentId: "create-area-modal",
			componentName: "modal.create.title",
			trNamespace: "menu",
			buttonName: "modal.create.button-label",
			buttonIcon: "pi pi-plus",
			showButton: showButton,
			useTranslation: false,
			buttons: btns,
			content: formContent,
			className: "modal-h-90"
		};
	}

	AddToCartModalPromotedProperties(
		createFunction,
		formContent,
		item,
		dataSteps = 1,
		canOrder = false,
		showButtonAdditionalClassName = "btn-primary"
	) {
		var btnId = `add-to-cart-button-id-${item.Id}`;
		var btns = [];
		if (canOrder) {
			btns = [
				{
					title: "cancel",
					trNamespace: "common",
					icon: "fa-solid fa-square-xmark m-2",
					actionUrl: `${company.companyId}/menu`,
					className: "btn-danger",
					isCloseModal: true
				},
				{
					title: "add",
					trNamespace: "common",
					icon: "fa-solid fa-basket-shopping-plus m-2",
					actionUrl: `${company.companyId}/menu`,
					className: "btn-primary",
					id: btnId,
					dataSteps: dataSteps,
					actionFunction: [
						() => {
							createFunction.forEach((element) => {
								element();
							});
						}
					],
					isCloseModal: false
				}
			];
		} else {
			btns = [
				{
					title: "cancel",
					trNamespace: "common",
					icon: "fa-solid fa-square-xmark m-2",
					actionUrl: `${company.companyId}/menu`,
					className: "btn-danger",
					isCloseModal: true
				}
			];
		}
		var showButton = {
			title: "",
			trNamespace: "menu",
			icon: "fa-solid fa-circle-info m-1",
			actionUrl: "",
			className: `btn ${showButtonAdditionalClassName} btn-sm btn-md-height `
		};
		if (canOrder) {
			showButton = {
				title: "",
				trNamespace: "common",
				icon: "fa-solid fa-basket-shopping-plus m-1",
				actionUrl: "",
				className: `btn ${showButtonAdditionalClassName} btn-sm btn-md-height `
			};
		}
		return {
			componentId: "create-area-modal",
			componentName: "modal.create.title",
			trNamespace: "menu",
			buttonName: "modal.create.button-label",
			buttonIcon: "pi pi-plus",
			showButton: showButton,
			useTranslation: false,
			buttons: btns,
			content: formContent,
			className: "modal-h-90"
		};
	}
	/**

 */

	CreateFormFields() {
		return {
			trNamespace: "menu",
			isVertical: true,
			fields: [
				{
					label: "modal.create.name",
					type: "text",
					id: "area-create-name",
					name: "Name",
					required: true,
					value: "",
					placeholder: "modal.create.name-placeholder"
				},
				{
					label: "modal.create.level-id",
					type: "integer",
					id: "area-create-level-id",
					name: "Level_Id",
					required: true,
					value: 1
				},
				{
					label: "modal.create.left-position",
					type: "integer",
					id: "area-create-left-position",
					name: "LeftPosition",
					required: true,
					value: 1
				},
				{
					label: "modal.create.top-position",
					type: "integer",
					id: "area-create-top-position",
					name: "TopPosition",
					required: true,
					value: 1
				},
				{
					label: "modal.create.left-size",
					type: "integer",
					id: "area-create-left-size",
					name: "LeftSize",
					required: true,
					value: 1
				},
				{
					label: "modal.create.top-size",
					type: "integer",
					id: "area-create-top-size",
					name: "TopSize",
					required: true,
					value: 1
				},
				{
					label: "modal.create.color",
					type: "color",
					id: "area-create-color",
					name: "Color",
					required: true,
					value: "#000000"
				}
			]
		};
	}

	UpdateFormFields(model) {
		return {
			trNamespace: "menu",
			isVertical: true,
			className: "app-text-black",
			fields: [
				{
					label: "modal.create.name",
					type: "text",
					id: "area-update-name",
					name: "Name",
					required: true,
					value: model.Name,
					placeholder: "",
					isDisabled: true,
					isHidden: true
				},
				{
					label: "modal.create.level-id",
					type: "integer",
					id: "area-update-level-id",
					name: "Level_Id",
					required: true,
					value: model.Level_Id,
					isDisabled: true,
					isHidden: true
				},
				{
					label: "modal.create.left-position",
					type: "integer",
					id: "area-update-left-position",
					name: "LeftPosition",
					required: true,
					value: model.LeftPosition,
					isDisabled: true,
					isHidden: true
				},
				{
					label: "modal.create.top-position",
					type: "integer",
					id: "area-update-top-position",
					name: "TopPosition",
					required: true,
					value: model.TopPosition,
					isDisabled: true,
					isHidden: true
				},
				{
					label: "modal.create.left-size",
					type: "integer",
					id: "area-update-left-size",
					name: "LeftSize",
					required: true,
					value: model.LeftSize,
					isDisabled: true,
					isHidden: true
				},
				{
					label: "modal.create.top-size",
					type: "integer",
					id: "area-update-top-size",
					name: "TopSize",
					required: true,
					value: model.TopSize,
					isDisabled: true,
					isHidden: true
				},
				{
					label: "modal.create.color",
					type: "color",
					id: "area-update-color",
					name: "Color",
					required: true,
					value: model.Color,
					isDisabled: true,
					isHidden: true
				},
				{
					label: "modal.create.is-active",
					type: "integer",
					id: "area-update-is-active",
					name: "IsActive",
					required: true,
					value: model.IsActive,
					isHidden: true,
					isDisabled: true
				},
				{
					label: "modal.create.creation-date",
					type: "date",
					id: "area-update-creation-date",
					name: "CreationDate",
					required: true,
					value: model.CreationDate,
					isHidden: true,
					isDisabled: true
				},
				{
					label: "modal.create.last-update-date",
					type: "date",
					id: "area-update-last-update-date",
					name: "LastUpdateDate",
					required: true,
					value: model.LastUpdateDate,
					isHidden: true,
					isDisabled: true
				},
				{
					label: "modal.create.user-id",
					type: "integer",
					id: "area-update-user-id",
					name: "UserId",
					required: true,
					value: model.UserId,
					isHidden: true,
					isDisabled: true
				},
				{
					label: "modal.create.id",
					type: "integer",
					id: "area-update-id",
					name: "Id",
					required: true,
					value: model.Id,
					isHidden: true,
					isDisabled: true
				}
			]
		};
	}

	VisibleUpdateFormFields(model, changeNameFunction, changeColorFunction) {
		return {
			trNamespace: "menu",
			isVertical: true,
			className: "app-text-black",
			fields: [
				{
					label: "modal.create.name",
					type: "text",
					id: "area-name-update",
					name: "Name",
					required: true,
					value: model.Name,
					placeholder: "",
					changeFunction: changeNameFunction
				},
				{
					label: "modal.create.color",
					type: "color",
					id: "area-color-update",
					name: "Color",
					required: true,
					value: model.Color,
					changeFunction: changeColorFunction
				}
			]
		};
	}

	UpdateButtonProperties(updatedFunctions) {
		return {
			title: "save",
			trNamespace: "common",
			icon: "fa-solid fa-floppy-disk",
			actionUrl: "",
			className: "btn-success w-100",
			isCloseModal: true,
			image: "/animated/save.gif",
			spritesheet: actionsAnims.SaveAnimation,
			actionFunction: [
				() => {
					updatedFunctions.forEach((element) => {
						element();
					});
				}
			]
		};
	}

	CreateItem(item) {
		console.log("item", item);
		if (global.COMPANY != null) {
			// var model = {
			// 	Id: 0,
			// 	IsActive: 1,
			// 	UserId: global.COMPANY.UserId,
			// 	CreationDate: new Date(),
			// 	LastUpdateDate: new Date(),
			// 	Name: document.getElementById("area-create-name").value,
			// 	Level_Id: document.getElementById("level-create-level-id").value,
			// 	LeftPosition: document.getElementById("area-create-left-position")
			// 		.value,
			// 	TopPosition: document.getElementById("area-create-top-position").value,
			// 	LeftSize: document.getElementById("area-create-left-size").value,
			// 	TopSize: document.getElementById("area-create-top-size").value,
			// 	Color: document.getElementById("area-create-color").value
			// };
			//await localService.CreateOne(model);
			//setLoaded(false);
		}
	}

	CustomCanvasProperties(items) {
		var _menu = items.map((item, i) => {
			return {
				x: item.LeftPosition,
				y: item.TopPosition,
				width: item.LeftSize,
				height: item.TopSize,
				fill: item.Color,
				id: `area-${i}`,
				selectCallbacks: item.selectCallbacks,
				moveCallbacks: item.moveCallbacks,
				selectedArea: item
			};
		});
		return {
			pageName: "canvas.title",
			trNamespace: "canvas",
			gridDefinition: {
				cols: 10,
				rows: 10,
				color: "#aaaaff",
				opacity: 0.3,
				pointsRadius: 2,
				titleProperties: {
					text: "Floor plan",
					fontSize: 12,
					position: { x: 0, y: 0 },
					padding: 10,
					draggable: true,
					width: 4,
					height: 1,
					fill: "#f3efa7"
				}
			},
			menu: _menu
		};
	}

	// ! Private functions
	// #region Private functions

	dataTableCols() {
		return [
			{
				header: "table-fields.Id",
				field: "Id",
				sortable: true,
				isVisible: false,
				type: global.DATATABLE_COL_TYPE.number,
				width: "5em",
				order: 5
			},
			{
				header: "table-fields.CreationDate",
				field: "CreationDate",
				sortable: true,
				isVisible: false,
				type: global.DATATABLE_COL_TYPE.date,
				order: 5
			},
			{
				header: "table-fields.LastUpdateDate",
				field: "LastUpdateDate",
				sortable: true,
				isVisible: false,
				type: global.DATATABLE_COL_TYPE.date,
				order: 5
			},
			{
				header: "table-fields.IsActive",
				field: "IsActive",
				sortable: true,
				isVisible: true,
				type: global.DATATABLE_COL_TYPE.yesno,
				width: "8em",
				order: 3
			},
			{
				header: "table-fields.UserId",
				field: "UserId",
				sortable: true,
				isVisible: false,
				type: global.DATATABLE_COL_TYPE.number,
				width: "5em",
				order: 5
			},
			{
				header: "table-fields.Level_Id",
				field: "Level_Id",
				sortable: true,
				isVisible: true,
				type: global.DATATABLE_COL_TYPE.number,
				filter: true,
				width: "5em",
				order: 1,
				matchMode: FilterMatchMode.CONTAINS,
				showFilterButton: false
			},
			{
				header: "table-fields.Name",
				field: "Name",
				sortable: true,
				isVisible: true,
				type: global.DATATABLE_COL_TYPE.string,
				filter: true,
				width: "15em",
				order: 2,
				matchMode: FilterMatchMode.CONTAINS,
				showFilterButton: false
			},
			{
				header: "table-fields.LeftPosition",
				field: "LeftPosition",
				sortable: true,
				isVisible: true,
				type: global.DATATABLE_COL_TYPE.number,
				width: "5em",
				order: 4
			},
			{
				header: "table-fields.TopPosition",
				field: "TopPosition",
				sortable: true,
				isVisible: true,
				type: global.DATATABLE_COL_TYPE.number,
				width: "5em",
				order: 5
			},
			{
				header: "table-fields.LeftSize",
				field: "LeftSize",
				sortable: true,
				isVisible: true,
				type: global.DATATABLE_COL_TYPE.number,
				width: "5em",
				order: 6
			},
			{
				header: "table-fields.TopSize",
				field: "TopSize",
				sortable: true,
				isVisible: true,
				type: global.DATATABLE_COL_TYPE.number,
				width: "5em",
				order: 7
			},
			{
				header: "table-fields.Color",
				field: "Color",
				sortable: true,
				isVisible: true,
				type: global.DATATABLE_COL_TYPE.string,
				width: "8em",
				order: 8
			}
		];
	}

	dataTableFilters() {
		return {
			Name: {
				value: null,
				matchMode: FilterMatchMode.CONTAINS
			},
			Level_Id: {
				value: null,
				matchMode: FilterMatchMode.CONTAINS
			}
		};
	}

	// #endregion
}
export default AreaProperties;
