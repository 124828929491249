/**
 * ! Change CustomSpinner by your component name
 */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useToastContext } from "../../../context/toast-context.js";
import { initTranslations } from "../../../i18n/i18n.js";

const CustomSpinner = (_props) => {
	var props = _props.properties;

	const ticks = new Date().getTime();
	const componentId = "custom-spinner-" + ticks;

	const { t } = initTranslations();
	const { handleShowToast } = useToastContext();

	const [setLogo, setNewLogo] = useState(global.cplogo);
	const [loaded, setLoadedState] = useState(false);

	useEffect(() => {
		// Reload data on loaded state change.
		componentDidMount();
	}, [loaded]);

	async function componentDidMount() {
		try {
			var img = new Image();
			img.src = setLogo;
			img.onload = function () {
				setTimeout(() => {
					setLoadedState(true);
				}, 150);
			};
			img.onerror = function () {
				setNewLogo(require("../../../assets/commons/menu-128.png"));
				setTimeout(() => {
					setLoadedState(true);
				}, 150);
			};
		} catch (e) {
			onError(e);
		}
	}

	function onError(error) {
		handleShowToast("error", t("error", { ns: props.trNamespace }), `${error}`);
	}
	function setSpinner() {
		if (props.showSpinner ?? true) {
			return (
				<div
					className="spinner-border text-primary child-spinner-superpose2"
					role="status"
				></div>
			);
		}
	}
	return loaded ? (
		<center>
			<div className="m-4 parent-spinner-superpose" key={componentId}>
				{setSpinner()}
				<img
					src={setLogo}
					alt="logo"
					className="child-spinner-superpose1 animate glow delay-1"
				/>
			</div>
			<div className="m-4"></div>
			<div className="row animate blur  delay-1">
				<h6>{t("loading-datas", { ns: props.trNamespace ?? "common" })}</h6>
			</div>
		</center>
	) : null;
};

CustomSpinner.propTypes = {
	pageName: PropTypes.string,
	trNamespace: PropTypes.string,
	showSpinner: PropTypes.bool
};

export default CustomSpinner;
