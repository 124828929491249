// import
import { openDB } from "idb";

const dbName = `5f03fa5d-8427-4949-99e0-126ec8d51d41_m_${process.env.REACT_APP_NAME}_c_${process.env.REACT_APP_CONTEXT}`;
const product_datas_Table = "menu_datas_Table";
const product_datas_Index = "key";
const orders_Table = "orders_Table";
const orders_Index = "key";
const order_details_Table = "order_details_Table";
const order_details_Index = "key";
const company_details_Table = "company_details_Table";
const company_details_Index = "key";
const company_datas_Table = "company_datas_Table";
const company_datas_Index = "key";
const company_images_Table = "company_images_Table";
const company_images_Index = "key";
const category_datas_Table = "category_datas_Table";
const category_datas_Index = "key";
//
const products_Table = "products_Table";
const products_Index = "key";
const order_Table = "order_Table";
const order_Index = "key";
const company_Table = "company_Table";
const company_Index = "key";

let db = null;

/**
 * Opens the database and performs necessary upgrades if needed.
 * @returns {Promise<IDBDatabase>} The opened database.
 */
async function openDb() {
	db = await openDB(dbName, 1, {
		upgrade(db) {
			const a = db.createObjectStore(product_datas_Table, {
				keyPath: "Id",
				autoIncrement: true
			});
			a.createIndex(product_datas_Index, product_datas_Index);
			const b = db.createObjectStore(orders_Table, {
				keyPath: "Id",
				autoIncrement: true
			});
			b.createIndex(orders_Index, orders_Index);
			const c = db.createObjectStore(order_details_Table, {
				keyPath: "Id",
				autoIncrement: true
			});
			c.createIndex(order_details_Index, order_details_Index);
			const d = db.createObjectStore(company_details_Table, {
				keyPath: "Id",
				autoIncrement: true
			});
			d.createIndex(company_details_Index, company_details_Index);
			const e = db.createObjectStore(company_datas_Table, {
				keyPath: "Id",
				autoIncrement: true
			});
			e.createIndex(company_datas_Index, company_datas_Index);
			const f = db.createObjectStore(company_images_Table, {
				keyPath: "Id",
				autoIncrement: true
			});
			f.createIndex(company_images_Index, company_images_Index);
			const g = db.createObjectStore(products_Table, {
				keyPath: "Id",
				autoIncrement: true
			});
			g.createIndex(products_Index, products_Index);
			const h = db.createObjectStore(order_Table, {
				keyPath: "Id",
				autoIncrement: true
			});
			h.createIndex(order_Index, order_Index);
			const i = db.createObjectStore(company_Table, {
				keyPath: "Id",
				autoIncrement: true
			});
			i.createIndex(company_Index, company_Index);
			const j = db.createObjectStore(category_datas_Table, {
				keyPath: "Id",
				autoIncrement: true
			});
			j.createIndex(category_datas_Index, category_datas_Index);
		}
	});
	return db;
}

export default {
	openDb,
	dbName,
	product_datas_Table,
	product_datas_Index,
	orders_Table,
	orders_Index,
	order_details_Table,
	order_details_Index,
	company_details_Table,
	company_details_Index,
	company_datas_Table,
	company_datas_Index,
	company_images_Table,
	company_images_Index,
	category_datas_Table,
	category_datas_Index,
	products_Table,
	products_Index,
	order_Table,
	order_Index,
	company_Table,
	company_Index
};
