import {
	PostClaimAccess,
	PostClaimAppAccess
} from "../../routes/SSO/claim-access.js";
import idb from "../../local-db/logged-user.js";
import commons from "../common-services-funct.js";
import axios from "axios";
import { AppConfig } from "../../../config/app-config.js";
const RitsReactConsole = require("rits-node-framework/debug/rits-react-console.js");
const _logger = new RitsReactConsole(global.LOG_LEVEL);
//import testJson from '../dev/getAllDatas.json';

/**
 * Retrieves the users associated with a company.
 * @param {string} userId - The ID of the user.
 * @returns {Promise<Array>} - A promise that resolves to an array of users.
 */
/*
{
  "Email": "reynier.j@outlook.fr",
  "Password": "password"
}
*/

const delay = 1;
var isWorking = false;
var isAppWorking = false;
async function postClaimAccess(userEmail, userPassword) {
	if (isWorking) {
		return false;
	}
	if (global.TOKEN != null) {
		return true;
	}

	var res = false;
	isWorking = true;
	try {
		var body = {
			Email: userEmail,
			Password: userPassword
		};
		body = JSON.stringify(body);
		global.TOKEN = await ClaimAccess(PostClaimAccess(), body);
		if (global.TOKEN != null) {
			res = true;
			var user = { Email: userEmail, Password: userPassword };
			await idb.createLoggedUserId(user);
			global.LOGGED_USER = user;
		}
	} catch (err) {
		_logger.ERROR("postClaimAccess", [err]);
	}
	setTimeout(() => {
		isWorking = false;
	}, delay);
	return res;
}

async function postClaimAppAccess() {
	if (isAppWorking) {
		return false;
	}
	if (global.APP_TOKEN != null) {
		return true;
	}
	var res = false;
	isAppWorking = true;
	try {
		var body = AppConfig.AppLogin;
		body = JSON.stringify(body);
		global.APP_TOKEN = await ClaimAccess(PostClaimAppAccess(), body);
		if (global.APP_TOKEN != null) {
			res = true;
		}
	} catch (err) {
		_logger.ERROR("postClaimAccess", [err]);
	}
	setTimeout(() => {
		isAppWorking = false;
	}, delay);
	return res;
}

async function ClaimAccess(url, body) {
	var res = null;
	try {
		var header = commons.SetClassicHeaders(false);
		var response = await axios.post(url, body, header);
		var data = response.data;
		res = data.token;
	} catch (err) {
		_logger.ERROR("postClaimAccess", [err]);
	}

	return res;
}

async function checkIfLogged() {
	var res = false;
	var users = await idb.getLoggedUsers();
	if (users != null && users.length > 0) {
		var user = users[0];
		res = await postClaimAccess(user.Email, user.Password);
	}
	return res;
}

async function Logout() {
	var res = false;
	var users = await idb.getLoggedUsers();
	if (users != null && users.length > 0) {
		var user = users[0];
		if (user != null) {
			res = await idb.deleteLoggedUser(user);
		}
	}

	return res;
}

export default {
	postClaimAccess,
	postClaimAppAccess,
	checkIfLogged,
	Logout
};
