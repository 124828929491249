/**
 * ! Change HeadMenu by your component name
 */

import React, { useState, useEffect } from "react";
import { renderToString } from "react-dom/server";
import PropTypes from "prop-types";
import { useToastContext } from "../../../context/toast-context.js";
import { initTranslations } from "../../../i18n/i18n.js";
//import { LoadData } from "../../../data/services/load-data-service.js";
//import { LogoBo2_80Animation } from "../../../properties/animations/logos-animations.js";
import { setTheme, getTheme } from "../../models/themes.js";
import OrderThumbnail from "../specific/order-thumbnail.js";
const _companyModel = require("../../models/company.js");
const _basketModel = require("../../models/basket.js");

const HeadMenu = (_props) => {
	var props = _props.properties;
	const ticks = new Date().getTime();
	const componentId = "head-menu-" + ticks;
	const { t } = initTranslations();
	const { handleShowToast } = useToastContext();
	//const [userEmail, setLoaded] = useState("");
	const [loaded, setLoadedState] = useState(false);
	var custom = process.env.REACT_APP_COMPANY_ID
		? process.env.REACT_APP_LOGO
		: require("../../../assets/commons/menu-128.png");
	const [setLogo, setNewLogo] = useState(props.image ?? custom);

	setTheme(getTheme());
	var _cpLocal = _companyModel.getCompanyDatas();

	useEffect(() => {
		setTheme(getTheme());
		setNewLogo(custom);
		// Reload data on loaded state change.
		componentDidMount();
	}, [loaded, setLogo]);

	async function componentDidMount() {
		try {
			setTheme(getTheme());
			setLoadedState(true);
		} catch (e) {
			onError(e);
		}
	}

	const start = (_logo, animate) => {
		if (props.image == null || props.image == "") _logo = false;
		var imgComp = (imgComp = (
			<img src={setLogo} className="img-s-responsive border-rounded"></img>
		));
		if (_logo) {
			if (props.image == "null" || props.image.startsWith("http")) {
				imgComp = <img src={setLogo} className="img-s-responsive"></img>;
			} else {
				imgComp = (
					<img
						src={`data:image/png;base64,${props.image}`}
						className="img-s-responsive border-rounded m-0"
					></img>
				);
			}
		}
		return (
			<a
				href={`/${_cpLocal.companyId}/menu`}
				className={`${props.textClass} navbar-brand container-inline  ${
					animate ? "animate glow delay-1 nav-height-100}" : ""
				}`}
			>
				{imgComp}
				<h5 className="txt-header-title txt-primary font-bold left-0 m-1 mb-0 mt-0">
					{props.title}
				</h5>
			</a>
		);
	};
	const end =
		global.LOGGED_USER?.userEmail != null ? (
			<span className="text-warning font-bold ">
				<i className="text-warning font-bold fa-sharp fa-light fa-arrows-rotate"></i>
				&nbsp;{global.LOGGED_USER.userEmail}
			</span>
		) : (
			<span className="text-warning font-bold ">
				<i className="text-warning font-bold fa-sharp fa-light fa-arrows-rotate"></i>
				&nbsp;{"reload"}
			</span>
		);

	function setMenuItems() {
		if (props.showMenu == false) return "";
		var uls = (
			<>
				<ul
					className="navbar-nav me-auto mb-2 mb-lg-0 p-4 pb-0 pt-0 w-100"
					style={{ left: "15px !important", marginLeft: "0 !important" }}
				>
					<li className="animate pop">
						<a
							className="nav-link active txt-primary trn"
							href={`/${_cpLocal.companyId}/menu`}
						>
							<span>
								<i className={`fa-sharp fa-solid fa-plate-utensils`}></i>
								&nbsp;{t("menu", { ns: props.trNamespace })}
							</span>
						</a>
					</li>
					<li className="animate pop delay-2">
						<a
							className="nav-link active txt-primary trn"
							href={`/${_cpLocal.companyId}/details`}
						>
							<span>
								<i className={`fa-sharp fa-solid fa-user-chef`}></i>
								&nbsp;{t("details", { ns: props.trNamespace })}
							</span>
						</a>
					</li>
					<li className="animate pop delay-4">
						<a className="nav-link active trn" href={`/${_cpLocal.companyId}/`}>
							{end}
						</a>
					</li>
				</ul>
			</>
		);
		return uls;
	}

	function onError(error) {
		handleShowToast("error", t("error", { ns: "common" }), `${error}`);
	}

	function setBasket() {
		if (props.showOrders == false) return "";
		return (
			<h5 className="nav-item dropdown animate pop delay-1 m-1">
				<a
					data-id="basket-icon-main"
					className="nav-link active txt-primary trn"
					data-bs-toggle="offcanvas"
					href="#offcanvasBasket"
					role="button"
					onClick={() => {
						var content = setProductOrderThumbnail();
						var orderContent = document.getElementById("order-content");
						if (orderContent != null) {
							orderContent.innerHTML = renderToString(content);
							// Get all element with class btn-minus-qty
							var btnMinus = document.getElementsByClassName("btn-minus-qty");
							var btnPlus = document.getElementsByClassName("btn-plus-qty");
							var productsBasket = [];
							var basket = _basketModel.getCpyBasket(_cpLocal.companyId);
							productsBasket = basket.products;
							// Set event listener on each element
							for (var i = 0; i < btnMinus.length; i++) {
								btnMinus[i].addEventListener("click", function () {
									var itemId = this.getAttribute("data-item-id");
									// find item in basket where item.BasketId == itemId
									var item = productsBasket.find((x) => x.BasketId == itemId);

									setQty(false, item);
								});
							}
							for (i = 0; i < btnPlus.length; i++) {
								btnPlus[i].addEventListener("click", function () {
									var itemId = this.getAttribute("data-item-id");
									var item = productsBasket.find((x) => x.BasketId == itemId);

									setQty(true, item);
								});
							}
							var deleteButtons =
								document.getElementsByClassName("btn-order-delete");
							for (i = 0; i < deleteButtons.length; i++) {
								deleteButtons[i].addEventListener("click", function () {
									// if confirm
									if (confirm(t("orders.products.delete", { ns: "orders" }))) {
										var itemId = this.getAttribute("data-item-id");
										var item = productsBasket.find((x) => x.BasketId == itemId);
										_basketModel.deleteBasketProduct(
											item.BasketId,
											_cpLocal.companyId,
											_cpLocal.currency
										);
										// get element {`order-item-${props.item.BasketId}`}
										var orderItem = document.getElementById(
											`order-item-${item.BasketId}`
										);
										// remove element
										orderItem.remove();
									}
								});
							}
						}
					}}
					aria-controls="offcanvasBasket"
				>
					<span className="container-inline-right">
						<div className="parent-superpose m-2 mb-0 mt-0">
							<span className="child-superpose2">
								<i className={`fa-sharp fa-solid fa-basket-shopping`}></i>
							</span>
							<span
								className="position-absolute top-0 start-100 translate-middle badge bg-danger font-sm rounded-circle child-superpose1"
								data-id="basket-count-articles"
							></span>
						</div>
						<span
							className="badge bg-info m-2 font-sm mb-0 mt-0"
							data-id="basket-count"
						></span>
					</span>
				</a>
			</h5>
		);
	}

	function setProductOrderThumbnail() {
		var productsBasket = [];
		var basket = _basketModel.getCpyBasket(_cpLocal.companyId);
		productsBasket = basket.products;
		return productsBasket.map((item, count) => {
			count++;
			return (
				<OrderThumbnail
					key={count}
					properties={{ item: item, trNamespace: "orders", count: count }}
				></OrderThumbnail>
			);
		});
	}

	function setOffCanvas() {
		if (props.showOrders == false) return "";
		return (
			<div
				className="offcanvas offcanvas-end"
				tabIndex="-1"
				id="offcanvasBasket"
				aria-labelledby="offcanvasBasketLabel"
			>
				<div className="offcanvas-header bg-primary">
					<h4 className="offcanvas-title font-bold" id="offcanvasBasketLabel">
						<div className="container-inline m-2 mb-0 mt-0">
							<div className="container-inline">
								<span>{t("common:basket")}</span>
								<span
									className="m-2 mb-0 mt-0 badge bg-danger font-sm rounded-circle"
									data-id="basket-count-articles"
								></span>
							</div>
							<button
								className=" m-4 mb-0 mt-0 btn btn-danger btn-sm"
								type="button"
								onClick={() => {
									if (confirm(t("orders:delete-all", { ns: "orders" }))) {
										_basketModel.deleteAllBasketProducts(
											_cpLocal.companyId,
											_cpLocal.currency
										);
										// remove all children of order-content
										var orderContent = document.getElementById("order-content");
										if (orderContent != null) {
											orderContent.innerHTML =
												"<h6>" + t("orders:empty-basket") + "</h6>";
										}
									}
								}}
							>
								<i className="fa fa-trash"></i>
								<span className="m-2 mb-0 mt-0">
									{t("orders:delete-all-basket")}
								</span>
							</button>
						</div>
					</h4>

					<button
						type="button"
						className="btn-close btn-secondary"
						data-bs-dismiss="offcanvas"
						aria-label="Close"
					></button>
				</div>
				<div className="offcanvas-body bg-light">
					<div id="order-content"></div>
				</div>
				<div className="offcanvas-footer bg-primary">
					<div className="row m-4  h-100">
						<div className="col">
							<div className="row ">
								<h6 className="font-bold" id="offcanvasBasketLabel">
									<span>{t("orders:total-price")}</span>
								</h6>
							</div>
							<div className="row">
								<h4>
									<span className="font-bold" data-id="basket-count"></span>
								</h4>
							</div>
						</div>
						<div className="col">
							<button
								className="btn btn-info font-bold float-end centered m-2 w-100"
								type="button"
							>
								{t("orders:place-order")}
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}

	function setQty(add, item) {
		var el = document.getElementById("total-qty-" + item.BasketId);
		if (el != null) {
			var qty = getQty(item);
			if (add) {
				qty++;
			} else {
				if (qty > 1) {
					qty--;
				}
			}
			el.innerHTML = qty;
			item.TotalQty = qty;
			setTotalPrice(item);
		}
	}
	function getQty(item) {
		var qty = 1;
		var el = document.getElementById("total-qty-" + item.BasketId);
		if (el != null) {
			qty = parseInt(el.innerHTML);
		}
		return qty;
	}
	function setTotalPrice(item) {
		// get element total-price
		var totalPrice = document.getElementById("total-price-" + item.BasketId);
		// set visible
		if (totalPrice != null) {
			var ttUnit = getTotalPrice(item);
			var qty = document.getElementById("total-qty-" + item.BasketId).innerHTML;
			totalPrice.innerHTML =
				(parseFloat(ttUnit) * parseInt(qty)).toFixed(2) +
				" " +
				_cpLocal.currency;
			var baskets = _basketModel.getCpyBasket(_cpLocal.companyId);
			// get basket with item.BasketId == BasketId
			var basket = baskets.products.find((x) => x.BasketId == item.BasketId);
			// set qty and price
			basket.TotalQty = qty;
			basket.TotalPrice = (parseFloat(ttUnit) * parseInt(qty)).toFixed(2);
			_basketModel.updateBaskets(baskets);
		}
		_basketModel.setBasketPriceAndQty({
			currency: _cpLocal.currency,
			cpid: _cpLocal.companyId
		});
	}
	function getTotalPrice(item) {
		var price = parseFloat(item.TotalUnitPrice);
		return price.toFixed(2);
	}

	return loaded ? (
		<>
			<nav
				className="navbar navbar-expand-lg bg-primary-gradient txt-primary shadow fixed-top"
				key={componentId}
			>
				<div className="container-fluid container-inline-spaced">
					{start(true, false)}
					<div className="container-inline-spaced">
						<div className="d-lg-none">{setBasket()}</div>
						<button
							className="navbar-toggler m-1 mb-0 mt-0"
							type="button"
							data-bs-toggle="collapse"
							data-bs-target="#navbarText"
							aria-controls="navbarText"
							aria-expanded="false"
							aria-label="Toggle navigation"
						>
							{" "}
							<span className="navbar-toggler-icon"></span>
						</button>
					</div>
					<div className="collapse navbar-collapse p-2 " id="navbarText">
						{setMenuItems()}
					</div>
					<div className="d-none d-lg-block">{setBasket()}</div>
				</div>
			</nav>
			{setOffCanvas()}
		</>
	) : (
		<nav
			className="navbar navbar-expand-lg bg-primary-gradient txt-primary  shadow fixed-top"
			key={componentId}
		>
			<div className="container-fluid container-inline-spaced">
				{start(true, true)}
			</div>
		</nav>
	);
};

HeadMenu.propTypes = {
	pageName: PropTypes.string,
	trNamespace: PropTypes.string,
	image: PropTypes.string,
	title: PropTypes.string,
	addedClass: PropTypes.string,
	textClass: PropTypes.string,
	menuButtonClass: PropTypes.string,
	showOrders: PropTypes.bool,
	// bool with default value = true
	showMenu: PropTypes.bool
};

export default HeadMenu;
