/**
 * ! Change DocumentModal by your component name
 */

import React from "react";
import PropTypes from "prop-types";
import { initTranslations } from "../../../i18n/i18n.js";

const DocumentModal = (_props) => {
	var props = _props.properties;
	const componentId =
		"DocumentModal-" + Math.random().toString(36).substring(7);

	const { t } = initTranslations();

	componentDidMount();

	async function componentDidMount() {
		try {
			//console.log(`Loaded ${props.pageName} with id ${componentId}`);
		} catch (e) {
			onError(e);
		}
	}

	function onError(error) {
		console.log(error);
	}

	return (
		<div
			className="modal modal-xl"
			key={componentId}
			id={props.modalId}
			role="dialog"
			aria-labelledby={props.modalId}
			aria-hidden="true"
			style={{ zIndex: 3001 }}
		>
			<div className="modal-dialog">
				<div className="modal-content">
					<div className="modal-header">
						<h4 className="modal-title trn">
							{t(props.modalTitleKey, { ns: props.trNamespace })}
						</h4>
						<button
							className="btn-close"
							type="button"
							data-bs-dismiss="modal"
							aria-label="Close"
						></button>
					</div>
					<div className="modal-body">
						<object
							data={`${props.documentUrl}`}
							type="application/pdf"
							width="100%"
							height="800px"
						>
							<p className="trn">{t("pdf.error", { ns: props.trNamespace })}</p>
							<a
								className="btn btn-primary trn"
								href="/lib/pdf/privacy.pdf"
								download="download"
							>
								{t("pdf.download", { ns: props.trNamespace })}
							</a>
						</object>
					</div>
					<div className="modal-footer">
						<button
							className="btn btn-danger trn"
							type="button"
							data-bs-dismiss="modal"
						>
							{t("close", { ns: props.trNamespace })}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

DocumentModal.propTypes = {
	pageName: PropTypes.string,
	trNamespace: PropTypes.string,
	documentUrl: PropTypes.string,
	modalId: PropTypes.string,
	modalTitleKey: PropTypes.string
};

export default DocumentModal;
