// import
import { openDB } from "idb";

const dbName = `f3db67d8-74e3-43b6-a1a5-0e9b88796426_m_${process.env.REACT_APP_NAME}_c_${process.env.REACT_APP_CONTEXT}`;
const loggedUserTable = "2a5331af-f908-4833-a28d-d2ffbf0ba6a9";
const loggedUserIndex = "Key";

var db = null;

/**
 * Opens the database and performs necessary upgrades if needed.
 * @returns {Promise<IDBDatabase>} The opened database.
 */
async function openDb() {
	db = await openDB(dbName, 1, {
		upgrade(db) {
			const userId = db.createObjectStore(loggedUserTable, {
				keyPath: "Email",
				autoIncrement: true
			});
			userId.createIndex(loggedUserIndex, loggedUserIndex);
		}
	});
	return db;
}

// #region Logged User
/**
 * Adds a computer ID to the local database.
 *
 * @param {Object} item - The computer ID to be added.
 * @returns {Promise<boolean>} - A promise that resolves to true if the computer ID was added successfully, or false if it already exists.
 */
async function createLoggedUserId(item) {
	if (db == null) {
		await openDb();
	}
	var cp = await getLoggedUsers();
	if (cp.length > 0) {
		//check if the computerId already exists in any of the records
		var found = cp.find((x) => x.Email === item.Email);
		if (found != null) {
			return false;
		}
	}
	// TODO: Change this!
	await db.add(loggedUserTable, {
		Email: item.Email,
		Password: item.Password
	});
	return true;
}
/**
 * Retrieves the computer IDs from the local database.
 * @returns {Promise<Array>} A promise that resolves to an array of computer IDs.
 */
async function getLoggedUsers() {
	if (db == null) {
		await openDb();
	}
	var users = await db.getAll(loggedUserTable);
	return users;
}
/**
 * Retrieves a computer record from the local database based on the provided computerId.
 * @param {Object} item - The computerId to search for in the records.
 * @returns {Object|null} - The computer record if found, otherwise null.
 */
async function getLoggedUser(item) {
	if (db == null) {
		await openDb();
	}
	var cp = await getLoggedUsers();
	if (cp.length > 0) {
		//check if the computerId already exists in any of the records
		var found = cp.find((x) => x.Email === item.Email);
		if (found != null) {
			return found;
		}
	}
	return null;
}
/**
 * Updates the computer ID in the local database.
 * @param {Object} item - The new computer ID to be updated.
 * @returns {Promise<void>} - A promise that resolves when the update is complete.
 */
async function updateLoggedUser(item) {
	if (db == null) {
		await openDb();
	}
	var cp = await getLoggedUser(item);
	if (cp == null) {
		return false;
	}
	await db.put(loggedUserTable, item);
	return true;
}
/**
 * Deletes a computer ID from the local database.
 * @param {Object} item - The ID of the computer to delete.
 * @returns {Promise<void>} - A promise that resolves when the computer ID is deleted.
 */
async function deleteLoggedUser(item) {
	if (db == null) {
		await openDb();
	}
	var cp = await getLoggedUser(item);
	if (cp == null) {
		return false;
	}
	await db.delete(loggedUserTable, item.Email);
	return true;
}
// #endregion

export default {
	createLoggedUserId,
	getLoggedUsers,
	getLoggedUser,
	updateLoggedUser,
	deleteLoggedUser
};
