/**
 * @file Toast Context
 * @module ToastContext
 * @description Provides a context for displaying toast notifications.
 */

import PropTypes from "prop-types";
import { createContext, useContext, useRef } from "react";
import { Toast } from "primereact/toast";
//import "primereact/resources/themes/lara-light-cyan/theme.css";

/**
 * Context for displaying toast notifications.
 * @typedef {Object} ToastContext
 * @property {function} handleShowToast - Function to show a toast notification.
 */

const ToastContext = createContext(undefined);

/**
 * Provides a context for displaying toast notifications.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The child components.
 * @returns {ReactNode} The rendered component.
 */
export const ToastContextProvider = ({ children }) => {
  const toastRef = useRef(null);

  /**
   * Shows a toast notification.
   * @param {Object} options - The options for the toast notification.
   * @param {string} options.severity - The severity of the toast notification.
   * @param {string} options.summary - The summary of the toast notification.
   * @param {string} options.detail - The detail of the toast notification.
   */
  const showToast = (options) => {
    if (!toastRef.current) return;
    toastRef.current.show(options);
  };

  /**
   * Handles showing a toast notification.
   * @param {string} type - The type of the toast notification.
   * @param {string} title - The title of the toast notification.
   * @param {string} message - The message of the toast notification.
   */
  const handleShowToast = (type, title, message) => {
    showToast({
      severity: type,
      summary: title,
      detail: message,
    });
  };

  return (
    <ToastContext.Provider value={{ handleShowToast }}>
      <Toast ref={toastRef} position="bottom-center" />
      {children}
    </ToastContext.Provider>
  );
};

ToastContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

/**
 * Custom hook to access the ToastContext.
 * @returns {ToastContext} The ToastContext object.
 * @throws {Error} Throws an error if used outside of ToastContextProvider.
 */
export const useToastContext = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error("useToastContext must be used within ToastContextProvider");
  }
  return context;
};
