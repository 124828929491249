/**
 * REACT
 */
import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
/**
 * Context
 */
import { useToastContext } from "../../context/toast-context.js";
import { initTranslations } from "../../i18n/i18n.js";
/**
 * Logger
 */
const RitsReactConsole = require("rits-node-framework/debug/rits-react-console.js");
const _logger = new RitsReactConsole(global.LOG_LEVEL);
/**
 * Services
 */
import {
	LoadData,
	LoadProductsFromCategory
} from "../../data/services/load-data-service.js";
/**
 * Components
 */
import CustomSpinner from "../common/shared/custom-spinner.js";
import HeadMenu from "../common/headers/head-menu.js";
import ProductCard from "../common/specific/product-card.js";
import PromotedProductCard from "../common/specific/promoted-product-card.js";
import CategoryCard from "../common/specific/category-card.js";
import CustomFooter from "../common/shared/custom-footer.js";
/**
 * Models
 */
const _model = require("../models/dashboard.js");
const _basketModel = require("../models/basket.js");
const _companyModel = require("../models/company.js");
import { setTheme, getTheme } from "../models/themes.js";
/**
 * Constants
 */
const isBrowser = typeof window !== `undefined`;

/**
 * Dashboard
 * @param {*} _props
 * @returns
 */
const Dashboard = (_props) => {
	var props = _props.properties;
	const ticks = new Date().getTime();
	const componentId = "page-dashboard-" + ticks;
	const [loaded, setLoaded] = useState(false);
	const [userEmail, setUserEmail] = useState(null);
	const [companyDatas, setCompanyDatas] = useState(null);
	const [promotedProducts, setPromotedProducts] = useState(null);
	const [categoriesDatas, setCategoriesDatas] = useState(null);
	const { t } = initTranslations();
	const { handleShowToast } = useToastContext();
	// Show params
	const [showAllergens, setShowAllergens] = useState(false);
	const [showIngredients, setShowIngredients] = useState(false);
	const [showNutriscore, setShowNutriscore] = useState(false);
	const [showProductDescription, setShowProductDescription] = useState(false);
	const [showProductImage, setShowProductImage] = useState(false);
	const [canOrder, setCanOrder] = useState(false);
	const effectRan = useRef(false);
	const navigate = useNavigate();
	const _promotedColor = "primary-dark";
	// Specific
	setTheme(getTheme());
	useScrollPosition();
	var isClick = false;

	/**
	 * Use effect
	 */
	useEffect(() => {
		if (!effectRan.current) {
			props = _props.properties;
			// Reload data on loaded state change.
			componentDidMount();
		}
		return () => {
			effectRan.current = true;
		};
	}, [loaded]);
	/**
	 * Component did mount
	 */
	async function componentDidMount() {
		try {
			await loadAll();
		} catch (e) {
			onError(e);
		}
	}
	/**
	 * Load all datas
	 */
	async function loadAll() {
		var data = global.COMPANY_DATAS;
		if (data == null) {
			data = await LoadData();
			global.COMPANY_DATAS = data;
		}
		if (data != null && data.companyName != null) {
			setTheme(data.company.CompanyParams);
			//console.log(data);
			setShowAllergens(data.company.CompanyParams.ShowAllergens);
			setShowIngredients(data.company.CompanyParams.ShowIngredients);
			setShowNutriscore(data.company.CompanyParams.ShowNutriscore);
			setShowProductDescription(
				data.company.CompanyParams.ShowProductDescription
			);
			setShowProductImage(data.company.CompanyParams.ShowProductImage);
			props.pageName = data.companyName;
			props.base64Image = data.companyDetails.CompanyLogo;
			setUserEmail(data.name);
			setCompanyDatas(data);
			var promotedProducts = data.products.filter((item) => {
				if (item.ProductOptions != null && item.ProductOptions.length > 0) {
					return item.ProductOptions[0].Promoted;
				}
			});
			setPromotedProducts(promotedProducts);
			setCategoriesDatas(data.categories);
			setCanOrder(data.company.CompanyParams.CanOrder);
			_logger.DEBUG(
				`Loaded ${props.pageName} with id ${componentId} and company ${data.companyName} logged user ${userEmail}`
			);
			document.title = props.pageName;
			_companyModel.setCompanyDatas({
				companyId: data.company.CompanyId,
				companyName: data.companyName,
				companyLogo: data.companyDetails.CompanyLogo,
				currency: data.company.CompanyParams.Currency,
				companyParams: data.company.CompanyParams
			});
			// wait for seconds
			setTimeout(() => {
				setLoaded(true);
				setTimeout(() => {
					_basketModel.setBasketPriceAndQty({
						currency: data.company.CompanyParams.Currency,
						cpid: data.company.CompanyId
					});
					selectFirstMenu();
				}, 500);
			}, 50);
		} else {
			setTimeout(async () => {
				await loadAll();
			}, 500);
		}
	}
	/**
	 *  On error
	 */
	function onError(error) {
		//console.log(error);
		if (process.env.REACT_APP_ENV == "development") {
			handleShowToast("error", t("error", { ns: "common" }), `${error}`);
		}
		navigate("/");
	}
	/**
	 * Get the scroll position
	 * @param {*} param0
	 * @returns
	 */
	function getScrollPosition({ element, useWindow }) {
		if (!isBrowser) return { x: 0, y: 0 };

		const target = element ? element.current : document.body;
		const position = target.getBoundingClientRect();

		return useWindow
			? { x: window.scrollX, y: window.scrollY }
			: { x: position.left, y: position.top };
	}
	/**
	 * Use scroll position
	 * @param {*} deps
	 * @param {*} element
	 * @param {*} useWindow
	 * @param {*} wait
	 */
	function useScrollPosition(deps, element, useWindow, wait) {
		const position = useRef(getScrollPosition({ useWindow }));

		let throttleTimeout = null;

		const callBack = () => {
			if (isClick) return;
			const currPos = getScrollPosition({ element, useWindow });
			var items = document.querySelectorAll(".cat-item-title");
			//var actualPos = pageHeight + currPos.y;
			for (var i = 0; i < items.length; i++) {
				var item = items[i];
				var rect = item.getBoundingClientRect();
				var top = rect.top;
				var category = item.getAttribute("data-category");
				// set radio checked
				var name = `btn-${category}`;
				var button = document.getElementsByName(name)[0];
				// get button index
				if (top > 130 && top < 360) {
					if (button != null) {
						var btns = document.querySelectorAll(".btn-breadcumb-category");
						// foreach btns remove :active state
						btns.forEach((btn) => {
							// active state
							btn.classList.remove("active");
						});
						button.classList.add("active");
						document.getElementById("breadcumb-cat-scroller").scrollLeft =
							button.offsetLeft - 15;
					}
				}
			}
			position.current = currPos;
			throttleTimeout = null;
		};

		useLayoutEffect(() => {
			const handleScroll = () => {
				if (wait) {
					if (throttleTimeout === null) {
						throttleTimeout = setTimeout(callBack, wait);
					}
				} else {
					callBack();
				}
			};

			window.addEventListener("scroll", handleScroll);

			return () => window.removeEventListener("scroll", handleScroll);
		}, deps);
	}
	/**
	 * Set the menus from datas
	 */
	function setMenus() {
		//console.log("setMenus()");
		// Get all categories distinct from companyDatas
		var previousId = 0;
		if (promotedProducts != null && promotedProducts.length > 0) previousId = 1;
		// Create dateTime now ticks
		var ticks = new Date().getTime();
		return (
			<>
				{setPromoted()}
				<div className="m-2">
					{categoriesDatas.map((category) => {
						var id = "c_" + ticks + "_" + category.Id;
						previousId++;
						return setCategory(category, id, previousId);
					})}
				</div>
			</>
		);
	}
	/**
	 * Select first menu
	 */
	function selectFirstMenu() {
		var firstCategory =
			document.getElementById("option0") ?? document.getElementById("option1");
		// click
		if (firstCategory != null) {
			firstCategory.click();
		}
		// var items = document.querySelectorAll(".cat-item-title");
		// for (var i = 0; i < items.length; i++) {
		// 	//useScrollPosition(null,items[i] );

		// 	//observer.observe(items[i]);
		// }
	}
	/**
	 * Set the breadcumb menu
	 * @returns
	 */
	function setBreadcumbMenu() {
		//console.log("setArianneMenu()");
		var list = categoriesDatas.map((item, count) => {
			count++;
			return (
				<>
					<button
						type="button"
						key={`category_btn_${item.CategoryName}_${item.Id}-${count}`}
						className={`btn btn-outline-primary-no-focus btn-breadcumb-category m-2 mt-0 mb-0 app-text-color animate spin delay-${count}`}
						name={`btn-${item.CategoryName}`}
						id={`option${count}`}
						data-category={item.CategoryName}
						data-category-position={item.PositionCategory}
						data-category-index-position={count}
						data-toggle="button"
						aria-pressed="false"
						onClick={(e) => {
							var category = e.target.getAttribute("data-category");
							var position = e.target.getAttribute("data-category-position");
							// scroll to element id `category-item-${category.Name}-${category.position}`
							var element = document.getElementById(
								`category-item-${category}-${position}`
							);
							// remove active to all buttons
							var btns = document.querySelectorAll(".btn-breadcumb-category");
							btns.forEach((btn) => {
								// active state
								btn.classList.remove("active");
							});
							// add active to button
							e.target.classList.add("active");
							isClick = true;
							if (element) {
								element.scrollIntoView({ behavior: "smooth" });
							}
							setTimeout(() => {
								isClick = false;
							}, 750);
						}}
					>
						{item.CategoryName}&nbsp;
						<i className="fas fa-angle-down"></i>
					</button>
				</>
			);
		});
		if (promotedProducts != null && promotedProducts.length > 0) {
			// Create another button for promoted and add it at top of list
			list.unshift(
				<button
					type="button"
					key={`category_btn_promoted`}
					className={`btn btn-outline-primary-no-focus btn-breadcumb-category m-2 mt-0 mb-0 app-text-color animate spin delay-1`}
					name={`btn-${t("common:promoted")}`}
					id={`option0`}
					data-category={t("common:promoted")}
					data-category-position={0}
					data-category-index-position={0}
					data-toggle="button"
					aria-pressed="false"
					onClick={(e) => {
						var category = e.target.getAttribute("data-category");
						var position = e.target.getAttribute("data-category-position");
						// scroll to element id `category-item-${category.Name}-${category.position}`
						var element = document.getElementById(
							`category-item-${category}-${position}`
						);
						// remove active to all buttons
						var btns = document.querySelectorAll(".btn-breadcumb-category");
						btns.forEach((btn) => {
							// active state
							btn.classList.remove("active");
						});
						// add active to button
						e.target.classList.add("active");
						isClick = true;
						if (element) {
							element.scrollIntoView({ behavior: "smooth" });
						}
						setTimeout(() => {
							isClick = false;
						}, 750);
					}}
				>
					{t("common:promoted")}&nbsp;
					<i className="fas fa-angle-down"></i>
				</button>
			);
		}
		return (
			<div
				className="flex-row flex-nowrap horizontal-scroller"
				id="breadcumb-cat-scroller"
			>
				{list}
			</div>
		);
	}
	/**
	 * Set the promoted components
	 * @param {*} products
	 * @returns
	 */
	function setPromotedComponents(products) {
		console.log(products);

		products = _model.orderPromotedProducts(products);

		return products.map((item, count) => {
			count++;
			return (
				<PromotedProductCard
					key={`category-item-${item.Id}-${count}`}
					properties={{
						pageName: props.pageName,
						trNamespace: props.trNamespace,
						item: item,
						count: count,
						showAllergens: showAllergens,
						showIngredients: showIngredients,
						showNutriscore: showNutriscore,
						showProductDescription: showProductDescription,
						showProductImage: showProductImage,
						canOrder: canOrder,
						companyDatas: companyDatas,
						additionalModalButtonClassName: `btn-${_promotedColor}`,
						isPromoted: true
					}}
				></PromotedProductCard>
			);
		});
	}
	/**
	 * Set the promoted
	 * @returns
	 */
	function setPromoted() {
		if (promotedProducts == null || promotedProducts.length == 0) return null;

		var category = {
			Id: 0,
			CategoryName: t("common:promoted"),
			PositionCategory: 0
		};
		var id = "c_" + ticks + "_" + category.Id;
		return (
			<div
				className={`row mt-0 pt-0 mb-4 ${`bg-${_promotedColor}-gradient border-rounded`} shadow`}
			>
				{setCategory(category, id, 0, `bg-${_promotedColor}-gradient`)}
				<div className="flex-row flex-nowrap horizontal-scroller  mt-0 pt-0">
					<div className="container-inline" style={{ overflowY: "hidden" }}>
						{setPromotedComponents(promotedProducts)}{" "}
					</div>
				</div>
			</div>
		);
	}
	/**
	 * Set the category from datas
	 * @param {} category
	 * @param {*} id
	 * @returns
	 */
	function setCategory(
		category,
		id,
		previousId,
		additionalCardClassName = null
	) {
		//console.log("setCategory()");
		var categoryItems = LoadProductsFromCategory(
			category.Id,
			companyDatas.products
		);
		// sort categoryItems by position then by Name
		categoryItems = _model.orderCategoryItems(categoryItems);
		var _class = "scroll-anchor";
		if (previousId <= 1) {
			_class = "scroll-anchor-first";
		}

		return (
			<div
				className={`${_class} cat-item-title`}
				id={`category-item-${category.CategoryName}-${category.PositionCategory}`}
				key={`category-item-${category.CategoryName}-${id}`}
				data-category={`${category.CategoryName}`}
			>
				<div
					className={`row`}
					key={`category-item-${category.CategoryName}-${id}-ct`}
				>
					<CategoryCard
						properties={{
							pageName: props.pageName,
							trNamespace: props.trNamespace,
							category: category,
							count: id,
							additionalCardClassName: additionalCardClassName
						}}
					></CategoryCard>

					<div className="m-2"></div>
					<center>
						<div className="row w-100">
							{categoryItems.map((item, count) => {
								var visible = true;
								if (
									item.ProductOptions != null &&
									item.ProductOptions.length > 0
								) {
									visible = item.ProductOptions[0].IsVisible;
								}
								if (
									item.Category[0].CategoryName === category.CategoryName &&
									visible
								) {
									return setProductCard(item, count);
								}
							})}
						</div>
					</center>
					<div className="m-2"></div>
				</div>
			</div>
		);
	}
	/**
	 * Set the product card
	 * @param {*} item
	 * @returns
	 */
	function setProductCard(item, count) {
		count += 2;

		return (
			<ProductCard
				key={`category-item-${item.Id}-${count}`}
				properties={{
					pageName: props.pageName,
					trNamespace: props.trNamespace,
					item: item,
					count: count,
					showAllergens: showAllergens,
					showIngredients: showIngredients,
					showNutriscore: showNutriscore,
					showProductDescription: showProductDescription,
					showProductImage: showProductImage,
					canOrder: canOrder,
					companyDatas: companyDatas
				}}
			></ProductCard>
		);
	}
	/**
	 * Set the component
	 */
	return !loaded ? (
		<div className="App bg-primary h-100">
			<header className="App-header">
				<CustomSpinner
					properties={{
						pageName: props.pageName,
						trNamespace: props.trNamespace
					}}
				/>
			</header>
		</div>
	) : (
		<div className="App " key={componentId + "-main"}>
			<HeadMenu
				properties={{
					pageName: "header",
					trNamespace: "headMenu",
					image: props.base64Image,
					title: props.pageName,
					addedClass: "w-100 bg-primary-gradient",
					textClass: "txt-primary",
					menuButtonClass: "txt-primary m-2",
					showOrders: canOrder,
					showMenu: true
				}}
			/>
			{/* <PageHeader properties={props} /> */}
			<div className="hilightbg-sticky-top-header bg-secondary-gradient shadow  h-100">
				{setBreadcumbMenu()}
			</div>
			<div
				className="content-container content-margin-top  h-100"
				key={componentId}
			>
				{<div className="app-side-margins">{setMenus()}</div>}
			</div>
			<div className="app-separator-extreme"></div>
			<CustomFooter></CustomFooter>
		</div>
	);
};

Dashboard.propTypes = {
	pageName: PropTypes.string,
	trNamespace: PropTypes.string,
	icon: PropTypes.string,
	backgroundImage: PropTypes.string,
	translatePageTitle: PropTypes.bool,
	showHeaderTooltip: PropTypes.bool,
	base64Image: PropTypes.string
};

export default Dashboard;
