global.TOKEN = null;
global.APP_TOKEN = null;
global.COMPANY = null;
global.LOG_LEVEL = ["DEBUG", "INFO", "WARN", "ERROR"];
global.DATATABLE_COL_TYPE = {
	yesno: "yesno",
	number: "number",
	string: "string",
	date: "date",
	truefalse: "truefalse",
	button: "button"
};
global.AREAS_RECT = [];
global.LOGGED_USER = null;
global.COMPANY_DATAS = null;