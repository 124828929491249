export const SaveAnimation = {
	name: "disk-animation",
	click: true,
	hover: true,
	clickDuration: 1000,
	hoverDuration: 300,
	id: "disk-button"
};

export const AddAnimation = {
	name: "add-animation",
	click: true,
	hover: true,
	clickDuration: 1000,
	hoverDuration: 300,
	id: "add-button"
};

export const CheckAnimation = {
	name: "check-animation",
	click: true,
	hover: true,
	clickDuration: 1000,
	hoverDuration: 1000,
	id: "check-button"
};
